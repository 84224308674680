import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Text, Title, Button } from "gatsby-theme-material-foundry"
import { makeStyles, Box, Container, Grid } from "@material-ui/core"
import SEO from "../components/SEO/seo"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
  coverPicture: {
    color: "white",
  },
  imgCard: {
    borderRadius: "6px !important",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "100%",
    height: "auto",
  },
  newsSection: {
    background: "rgba(206,241,207,.5)",
  },
  subtitle: {
    fontWeight: 600,
  },
  ptext: {
    fontSize: "1.2em",
  },
  buttonline: {
    height: "40px",
    marginTop: "-40px",
  },
  hero: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
}))

export default function StoreArrangement({ data }) {
  const classes = useStyles()
  const img20192 = data.ide_20192.childImageSharp.fluid
  const img20191 = data.ide_20191.childImageSharp.fluid
  const img2018 = data.ide_2018.childImageSharp.fluid
  const img2017 = data.ide_2017.childImageSharp.fluid
  const img20162 = data.ide_20162.childImageSharp.fluid
  const img20161 = data.ide_20161.childImageSharp.fluid
  const img2015 = data.ide_2015.childImageSharp.fluid

  const challenges = [
    {
      title: " 2019-2: Proptech innovation",
      subtitle: "Skap løsninger for bærekraftige bygninger og fremtidens by",
      image: img20191,
    },
    {
      title: "2019-1: e-helsepitch Førde",
      subtitle:
        " Hvordan kan ny digital teknologi gi KOLS-pasienter økt mestring i hverdagen?",
      image: img20192,
    },
    {
      title: "2018: EdTech",
      subtitle: "Skap løsninger for livslang læring",
      image: img2018,
    },
    {
      title: "2017: Helse",
      subtitle: "Skap løsninger for fremtidens Haukeland sykehus",
      image: img2017,
    },
    {
      title: "2016-2: Miljø og Energi",
      subtitle: "Skap løsninger for fremtidens miljø og energi",
      image: img20162,
    },
    {
      title: "2016-1: Helse og omsorg",
      subtitle: "Skap løsninger for fremtidens helse og omsorg",
      image: img20161,
    },
    {
      title: "2015: Bolig",
      subtitle: "Skap løsninger for fremtidens bolig",
      image: img2015,
    },
  ]
  return (
    <Layout>
      <SEO />
      <BackgroundImage
        alt={`cover picture`}
        fluid={data.cover.childImageSharp.fluid}
      >
        <Container
          maxWidth="md"
          align="center"
          className={classes.coverPicture}
        >
          <Box py="200px">
            <Title variant="h1" align="center" color="inherit">
              Faste arrangement
            </Title>
          </Box>
        </Container>
      </BackgroundImage>

      <Container maxWidth="md">
        <Box my={12}>
          <Title variant="h3" primary>
            Idékonkurranser
          </Title>

          <Text className={classes.ptext}>
            HVL Skape arrangerer hvert år idémesterksap og andre idéworkshops.
            Målgruppen er studenter ved HVL og andre interesserte studenter. De
            deltakende studentene får i oppdrag å komme med ideer og forslag til
            løsninger mot en spesifikk problemstilling eller tematikk. Tema for
            eventene varierer fra år til år og blir utarbeidet i tett samarbeid
            med fagmiljø, klynger og/eller andre samarbeidspartnere.
          </Text>
          <Title secondary variant="h3">
            Hva får deltakerne?
          </Title>
          <Text className={classes.ptext}>
            <ul>
              <li>
                Innføring i populære innovasjonsmetodikker som Design Thinking
                og Business Model Canvas
              </li>
              <li>Veiledning og oppfølging</li>
              <li>Deltakerbevis</li>
              <li>Premiepakke</li>
              <li>
                Middag hver dag under arrangementet og en festmiddag på
                pitchedagen
              </li>
            </ul>
            I tillegg vil studentene gjennom deltakelse få mulighet til å trene
            på presentasjonsteknikk og tverrfaglig gruppearbeid, samt at de får
            mulighet til å bygge nettverk.
          </Text>
        </Box>
      </Container>

      <Box className={classes.newsSection} py={8}>
        <Container maxWidth="md">
          <Box mb={4}>
            <Title variant="h3" align="center" black>
              Tidligere arrangement
            </Title>
          </Box>
          <Grid container spacing={4}>
            {challenges.map(c => (
              <Grid item xs={6} sm={4}>
                <Img fluid={c.image} className={classes.imgCard} />
                <Title variant="h5" black>
                  {c.title}
                </Title>
                <Text variant="h6" secondary>
                  {c.subtitle}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box mb={12}>
          <Box my={8}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Title variant="h2" primary>
                  SKAPE-kvelder
                </Title>
                <Text paragraph>
                  Dette er miniseminarer hvor vi trekker frem spesifikke
                  bransjer og næringer og ser på hvilke innovasjonsaktiviteter
                  som finnes her. Vi inviterer inn ulike bedrifter og aktører i
                  regionen, både offentlige og private, til å fortelle om sine
                  aktiviteter.
                </Text>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Img fluid={data.fabrikken.childImageSharp.fluid} />
              </Grid>
            </Grid>
          </Box>
          <Title variant="h2" primary>
            Studentutfordringen
          </Title>
          <Title variant="h4" component="h3" black>
            Hva er Studentutfordringen?
          </Title>
          <Text paragraph>
            Studentutfordringen er en lavterskel idékonkurranse hvor alle
            studenter ved HVL oppfordres til å jobbe med «ekte»
            problemstillinger og utfordringer knyttet til{" "}
            <a href="https://www.fn.no/Om-FN/FNs-baerekraftsmaal">
              FNs bærekraftsmål
            </a>
            . Utfordringene vil ha ulik faglig karakter og hentes inn fra
            regionale offentlige og private aktører. Gjennom Studentutfordringen
            ønsker HVL Skape å utvikle økt interesse for tverrfaglig samarbeid,
            kreativitet og innovasjon hvor alle studenter kan bidra.
          </Text>
          <Title variant="h4" component="h3" black>
            Hvordan deltar man?
          </Title>
          <Text paragraph>
            Studentutfordringen er en nettbasert konkurranse for hele HVL. En
            video av utfordringen blir lansert på monitorer rundt omkring på de
            ulike campusene. Studenter deltar i konkurransen ved å sende inn
            løsningsforslag via hvlskape.no.
          </Text>
          <Title variant="h4" component="h3" black>
            Hva får man ved å delta?
          </Title>
          <Text paragraph>
            Problemstiller vil være med i avgjørelsen av beste idé. Vinneren
            eller vinnergruppen får anerkjennelse i form av en premie og
            deltakerbevis. Studentutfordringen vil avholdes flere ganger i året.
            Første utfordring ut er Rektors utfordring.
          </Text>
          <Box display="flex" justifyContent="flex-end">
            <Button color="black" simple size="lg" to="/arrangement">
              Se alle arrangment
            </Button>
            <Button color="black" size="lg" to="/rektors-utfordring">
              Gå til rektors utfordring
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const ItemPageQuery = graphql`
  query FasteArrangement {
    cover: file(relativePath: { eq: "idemesterskap_cover.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(
          maxWidth: 2000
          duotone: { highlight: "#006381", shadow: "#004357" }
          traceSVG: { color: "#004357" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fabrikken: file(relativePath: { eq: "fabrikkgaten.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_2015: file(relativePath: { eq: "idemesterskap_2015.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_20161: file(relativePath: { eq: "idemesterskap_20161.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_20162: file(relativePath: { eq: "idemesterskap_20162.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_2017: file(relativePath: { eq: "idemesterskap_2017.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_2018: file(relativePath: { eq: "idemesterskap_2018.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_20191: file(relativePath: { eq: "idemesterskap_2019-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ide_20192: file(relativePath: { eq: "idemesterskap_2019-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
